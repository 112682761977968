@media (min-width: 425px) {
    .experience-cards-wrapper {
        max-width: 90vw;
        overflow: auto;
    }
}

.experience-cards-div
{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
    gap: 1rem 1rem;

    @media (min-width: 425px) {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        width: 1700px;
    }
}

.experience-heading {
    font-size: 56px;
    font-weight: 400;
}

.experience-container {
    display: flex;
    width: 90%;
    padding: 20px 10px;
    margin: 2rem auto 0;
}

.experience-container > * {
    flex: 1;
}

/* Media Query */

@media (max-width: 1380px) {
    .experience-heading {
        font-size: 40px;
    }
}

@media (max-width: 768px) {
    .experience-heading {
        font-size: 30px;
        text-align: center;
    }
}
