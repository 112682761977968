@import "../../style/globalColor";

$circleSize: 40px;
$childSize: 15%;

@keyframes sk-circleBounceDelay {
    0%, 80%, 100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1);
    }
}

@mixin rotate($deg) {
    transform: rotate($deg);
}

.sk-circle {
    margin: 100px auto;
    width: $circleSize;
    height: $circleSize;
    position: relative;

    .sk-child {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;

        &:before {
            content: "";
            display: block;
            margin: 0 auto;
            width: $childSize;
            height: $childSize;
            background-color: $iconBackground;
            border-radius: 100%;
            animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
        }
    }

    @for $i from 2 through 12 {
        .sk-circle#{$i} {
            $rotation: 30deg * $i;

            @include rotate($rotation);
        }

        .sk-circle#{$i}:before {
            animation-delay: -#{0.1 * ($i - 1)}s;
        }
    }
}

// Include the mixin in the respective classes
.sk-circle .sk-circle2 {
    @include rotate(30deg);
}

.sk-circle .sk-circle3 {
    @include rotate(60deg);
}

.sk-circle .sk-circle4 {
    @include rotate(90deg);
}

.sk-circle .sk-circle5 {
    @include rotate(120deg);
}

.sk-circle .sk-circle6 {
    @include rotate(150deg);
}

.sk-circle .sk-circle7 {
    @include rotate(180deg);
}

.sk-circle .sk-circle8 {
    @include rotate(210deg);
}

.sk-circle .sk-circle9 {
    @include rotate(240deg);
}

.sk-circle .sk-circle10 {
    @include rotate(270deg);
}

.sk-circle .sk-circle11 {
    @include rotate(300deg);
}

.sk-circle .sk-circle12 {
    @include rotate(330deg);
}
