@import "../../style/globalColor";

$imageHeight: 450px;

@keyframes scroll {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(calc(-100% + $imageHeight));
    }
}

.project-subtitle {
    @media (max-width: 768px) {
        text-align: center;
    }
}

.projects-container
{
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    gap: 1rem;
    padding: 0 0 2rem 0;
    user-select: none;

    .project-card
    {
        display: flex;
        flex-direction: column;
        min-width: 300px;
        background-color: $lightBackground2;
        padding: 1.5rem;
        border-radius: 10px;
        border: 1px solid $lightBorder1;
        transition: all 0.3s ease;

        &:hover {
            box-shadow: $lightBoxShadowDark 0 20px 30px -10px;
        }

        &.project-card-light {
            box-shadow: $lightBoxShadowDark 0 10px 30px -15px;

            &:hover {
                box-shadow: $lightBoxShadowDark 0 20px 30px -10px;
            }
        }

        &.project-card-dark {
            box-shadow: unset;
            background: $darkBackground;

            &:hover {
                box-shadow: unset;
            }
        }

        .project-detail
        {
            text-align: center;

            .card-title {
                color: $titleColor;
                font-size: 22px;
                line-height: 24px;
                font-weight: 700;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .card-subtitle {
                white-space: pre-wrap;
                color: $cardSubtitle;
                font-size: 17px;
                line-height: 1.5rem;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: auto;
            }
        }

        .project-image
        {
            position: relative;
            height: $imageHeight;
            max-height: $imageHeight;
            overflow: hidden;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;

            .card-image
            {
                max-width: 100%;
                height: auto;
                width: 100%;
                object-fit: contain;

                &.project-image-scroll {
                    @media (min-width: 1024px) {
                        animation: scroll 15s ease-in-out infinite;
                    }
                }
            }
        }

        .project-card-footer {
            display: flex;
            justify-content: space-around;
            margin-top: auto;

            .project-tag
            {
                background: $buttonColor;
                color: $lightBackground3;
                vertical-align: middle;
                align-items: center;
                border-radius: 4px;
                display: inline-flex;
                font-size: 0.75rem;
                height: 2em;
                justify-content: center;
                white-space: nowrap;
                line-height: 1.5;
                margin: 0 0.5rem;
                padding: 0 0.75em;
                cursor: pointer;
                transition: 0.2s ease-in;

                &:hover {
                    background: darken($buttonColor, 10%);
                }

                &.disable-link {
                    pointer-events: none;
                    opacity: 0.7;
                }
            }
        }

        @media (max-width: 768px) {
            .project-subtitle {
                font-size: 16px;
                text-align: center;
            }
        }
    }

}
