@import "style/globalColor";

.blog-wrapper
{
    min-height: calc(100vh - 190px);
    display: flex;
    flex-direction: column;

    header
    {
        padding: 2.5rem 20px;
        border-bottom: 2px solid $progressBarSpanBG;

        &.dark-header {
            border-bottom: 2px solid $progressBarSpanBG;
        }

        h1
        {
            height: 100%;
            font-size: 3rem;
            font-weight: 650;
            line-height: 1.3;
            margin: 0 0 20px 0;
            width: 70%;
            color: $buttonColor;

            &.light-title {
                color: $textColorDark;
            }

            @media (max-width: 768px) {
                width: 100%;
                font-size: 2.5rem;
            }
        }

        .blog-meta-data
        {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 20px;
            margin: 20px 0 0;
            width: fit-content;
            font-size: 1.2rem;
            font-weight: 400;

            @media (max-width: 768px) {
                font-size: 1rem;
            }

            .blog-category {
                font-weight: 400;
                background: $buttonHover;
                color: $textColorDark;
                padding: 5px 10px;
                border-radius: 20px;
            }
        }
    }

    .blog-content-wrapper
    {
        flex: 1 1;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
        gap: 20px;
        height: 100%;

        &.dark-wrapper {
            background: #070a12;
        }

        @media (max-width: 768px) {
            flex-direction: column;
        }

        .blog-content
        {
            flex: 0.8;
            padding: 2rem 20px 0;
            font-size: 1.2rem;
            font-weight: 400;
            line-height: 1.5;

            h3
            {
                margin: 4rem 0 0;
                line-height: 1;
                color: $buttonHover;

                &:first-child {
                    margin: 0;
                }

                &.dark-title {
                    color: $textColorDark;
                }
            }

            p {
                font-size: 1rem;
            }

            @media (max-width: 768px) {
                flex: 1 1;
            }
        }

        .blog-sidebar
        {
            position: relative;
            flex: 0.2;
            padding: 2rem 20px 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 20px;
            margin: 0 0 2rem 0;
            color: $buttonHover;
            border-left: 2px solid #000;

            &.light-sidebar {
                border-left: 2px solid $progressBarSpanBG;
            }

            @media (max-width: 768px) {
                flex: 1 1;
                padding: 0 20px 0;
                border-top: 2px solid $progressBarSpanBG;
                border-left: 0;
            }

            &.dark-sidebar
            {
                color: $textColorDark;
                border-left: 2px solid $progressBarSpanBG;

                @media (max-width: 768px) {
                    border-top: 2px solid $progressBarSpanBG;
                    border-left: 0;
                }
            }

            .blog-sidebar-content
            {
                position: sticky;
                top: 0;
                width: 100%;
                font-size: 1.2rem;
                font-weight: 400;
                line-height: 1.5;
                text-align: justify;

                .social-media-buttons-container {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-evenly;
                    align-items: center;
                }

                button
                {
                    display: inline-block;
                    text-align: center;
                    background: transparent;
                    color: $textColorDark;
                    font-size: 2rem;
                    font-weight: 400;
                    cursor: pointer;
                    transition: all 0.3s ease-in-out;
                    border: 0;

                    &.light-button {
                        color: $buttonHover;
                    }

                    @media (max-width: 768px) {
                        color: $textColorDark;
                    }
                }
            }
        }
    }
}
