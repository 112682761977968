@import "../../style/globalColor";

#topButton
{
    visibility: hidden;
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 99;
    border: none;
    outline: none;
    background-color: $buttonColor;
    color: white;
    cursor: pointer;
    padding: 15px;
    border-radius: 15px;
    font-size: 25px;

    @media only screen and (max-width: 768px) {
        bottom: 5%;
        right: 20%;
        width: 60px;
        height: 60px;
        border-radius: 50%;
    }
}

#topButton:hover {
    background-color: $topButtonHover;
    transition: all ease-in-out 0.2s;
}
